import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { SpinnerService } from './services/spinner.service';
import { NgIf } from '@angular/common';

@Component({
  standalone: true,
  selector: 'rtpca-spinner',
  imports: [NgIf],
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent {
  loading = false;

  constructor(readonly loader: SpinnerService, readonly cd: ChangeDetectorRef) {
    loader.getLoading().subscribe((value) => {
      this.loading = value;
      cd.detectChanges();
    });
  }
}
