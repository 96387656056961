<div
  *ngIf="loading"
  class="loading-container d-flex flex-column align-items-center justify-content-center"
>
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
  <div class="pt-3 fs-5">
    Please wait while the mapping file is being processed.
  </div>
</div>
