import { Route } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

export const appRoutes: Route[] = [
  {
    path: '',
    redirectTo: '/mapping',
    pathMatch: 'full',
  },
  {
    path: 'mapping',
    loadComponent: () =>
      import('./features/user-mapping/user-mapping.component').then(
        (m) => m.UserMappingComponent
      ),
    canActivate: [MsalGuard],
  },
  {
    path: 'program-config',
    loadComponent: () =>
      import('./features/program-config/program-config-table.component').then(
        (m) => m.ProgramConfigTableComponent
      ),
    canActivate: [MsalGuard],
  },
  {
    path: 'data-calendar',
    loadComponent: () =>
      import('./features/data-calendar/data-calendar.component').then(
        (m) => m.DataCalendarComponent
      ),
    canActivate: [MsalGuard],
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./homepage/homepage.module').then((m) => m.HomepageModule),
    canActivate: [MsalGuard],
  },
  {
    path: 'budget',
    loadComponent: () =>
      import('./features/budget/budget.component').then(
        (m) => m.BudgetComponent
      ),
    canActivate: [MsalGuard],
  },
];
